<template>
  <div class="add">
    <v-btn color="orange darken-2" fab dark x-small @click="cart_button_clicker()">
      <v-icon small>mdi-plus</v-icon>
    </v-btn>
    <v-bottom-sheet v-model="sheet" scrollable>
      <v-sheet height="70vh">
        <div class="text-center pt-2">
          <v-row justify="end">
            <v-btn text @click="sheet = !sheet">
              <v-icon>mdi-close-circle-outline</v-icon>
            </v-btn>
          </v-row>
        </div>
        <v-card v-if="product_id && sku_model && sku_tree" elevation="0">
          <div class="px-4 py-2" style="overflow: auto; height: calc(70vh - 170px)">
            <div v-for="sku_key in sku_model.slice(1)" :key="sku_key">
              <!-- <span class="subheading">{{ sku_tree.find(sku_key).value.field_name }}</span> -->
              <v-chip-group
                mandatory
                column
                active-class="primary--text"
                v-model="sku_model[sku_tree.find(sku_key).depth]"
              >
                <v-chip
                  v-for="child in sku_tree.find(sku_model[sku_tree.find(sku_key).depth - 1])
                    .children"
                  :key="child.key"
                  :value="child.key"
                  @click="sku_clicked(sku_tree.find(child.key))"
                >
                  {{ child.value.choice_name }}
                </v-chip>
              </v-chip-group>
            </div>
          </div>
        </v-card>

        <v-footer fixed class="py-3">
          <v-row class="mb-5">
            <v-col cols="8">
              <div v-if="product.is_onsale">
                <div class="deep-orange--text text-body2">
                  <span class="text-decoration-line-through">
                    {{ selected_sku_info.value.sku_product.price }}
                  </span>
                  <span>
                    {{ ` ${selected_sku_info.value.sku_product.sale_price} 円` }}
                  </span>
                  <span class="ml-3 black--text">
                    (税込
                    {{
                      Math.floor(
                        selected_sku_info.value.sku_product.sale_price * (1 + product.tax),
                      )
                    }}円)
                  </span>
                </div>
              </div>
              <div v-else>
                <div class="deep-orange--text text-body2">
                  {{ selected_sku_info.value.sku_product.price }}円
                  <span class="ml-3 black--text">
                    (税込
                    {{
                      Math.floor(selected_sku_info.value.sku_product.price * (1 + product.tax))
                    }}円)
                  </span>
                </div>
              </div>
            </v-col>
            <!-- <v-col cols="0"></v-col> -->
            <v-col cols="4" class="">
              <v-btn
                icon
                color="light-blue"
                x-small
                @click="() => handleMinus()"
                :disabled="sku_number < 2"
              >
                <v-icon>mdi-minus-circle-outline</v-icon>
              </v-btn>

              <span class="mx-2">
                {{ sku_number }}
              </span>
              <v-btn icon color="light-blue" x-small @click="() => handlePlus()">
                <v-icon>mdi-plus-circle-outline</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-btn
            block
            rounded
            class="orange darken-2 white--text"
            elevation="0"
            :disabled="expired"
            @click="add_to_cart"
          >
            <div>確認</div>
          </v-btn>
        </v-footer>
      </v-sheet>
    </v-bottom-sheet>
    <v-dialog v-model="dialog">
      <!-- <v-btn>カートに追加しました</v-btn> -->
      <div class="message">
        <div class="content">カートに追加しました</div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
  // import { cartMethods } from '@/utils/methods.js';
  import Methods from '@/api/method.js';
  // import _ from 'lodash';
  import Tree from '@/api/GlobalData.js';
  import { contactURL } from '@/utils/const';

  export default {
    data: () => ({
      sheet: false, // show sheet if added to the scart
      reviewContent: '',
      image_slider: 0, //商品説明画像
      review_slider: 0, //口コミのslide

      expired: false, //シェア買いのリンクの期限が切れた
      inventory_error: false, //在庫切れ

      selected_sku: {},
      minimum_sku: {},

      sku_number: 1,
      product: {
        price: null,
        price_ori: null,
        text: '',
        reviews: [],
        sold: null,
        price_ref: null,
        needed: null,
        images: [],
        have_category: false,
        categories: [],
      },
      product_text: '',

      recieve_date: null,

      rating: 5,
      shared: 0,
      //friends:[],
      //friends_count:0,
      dialog1: false,
      dialog2: false,
      e1: 1,
      carousel: 0, //expansion panel
      length: 2, //length of carousel
      expansionModel: 0,
      delivery_schedule: null,

      category: 0,
      price: null,
      price_ori: null,
      text: '',
      reviews: [],
      sold: null,
      price_ref: null,
      needed: null,
      images: [],
      have_category: false,
      categories: [],

      loading: true,

      dialog: false,
      number: 1,

      /* sku tree*/
      sku_tree: null,
      sku_model: null, //a chain of nodes' key, directed to the SKU LEAF
      selected_sku_key: null,
    }),

    props: ['product_id'],

    watch: {
      dialog(val) {
        if (!val) return;
        setTimeout(() => (this.dialog = false), 1000);
      },
    },

    async created() {
      this.product = this.product_id;
      let data = this.product_id.sku_objects;
      // console.log('product', this.product.preservation_category);
      // const tree = new Tree(1, 'root');

      const tree = new Tree(data, 0);

      // let print = [...tree.preOrderTraversal()];
      // console.log('print tree', print);

      let model = [...tree.getOneChain()].reverse();
      // console.log('model', model);

      this.sku_model = model;
      // this.selected_sku_key = model[model.length - 1];

      // this.minimum_sku = tree.find(this.selected_sku_key);
      // console.log(this.selected_sku);
      // let print = tree.preOrderTraversal()

      let price = 100000;
      for (let sku of data) {
        if (sku.is_leaf == true) {
          if (sku.sku_product.price < price) {
            price = sku.sku_product.price;
            this.selected_sku_key = sku._id;
          }
        }
      }
      this.product.price = price;
      this.minimum_sku = tree.find(this.selected_sku_key);
      if (this.product.is_onsale && this.product.sale_discount) {
        this.product.sale_price = Math.floor(price * this.product.sale_discount);
      }

      this.sku_tree = tree;
      // console.log(this.sku_tree);
      // console.log(this.sku_model.slice(1));

      // console.log('created');

      // const reviewData = await Methods.getReviews({
      //   product_id: this.product_id,
      //   page: 0,
      //   perPage: 1000,
      // });
      // this.reviews = reviewData.data.reviews;

      /* お届け予定日 */

      // this.recieve_date = response.data.delivery_date;
      // let temp_date = new Date(response.data.date);
      // temp_date = new Date(temp_date.setDate(temp_date.getDate() + response.data.delivery_days_expected));
      // let temp_month = Number(temp_date.getMonth())+1;
      // this.recieve_date = temp_month +"月"+temp_date.getDate()+"日";

      this.loading = false;
      // this.price = this.product.choices[0].price;
    },
    computed: {
      selected_sku_info: function () {
        // if (!this.sku_tree){
        //   return {
        //     price: 0,
        //   };
        // }
        // const sku = this.sku_tree.find(this.selected_sku_key);
        // const price = sku.value.sku_product.price || 0;
        // return {
        //   price,
        //   ...sku
        // };
        let sku_info = this.sku_tree.find(this.selected_sku_key);
        if (this.product.is_onsale) {
          sku_info.value.sku_product.sale_price = Math.floor(
            (sku_info.value.sku_product.price || 0) * this.product.sale_discount,
          );
        }
        return sku_info;
        // for (let sku of this.product.skus) {
        //   // console.log(sku.sku_fields)
        //   // memo: cannot compare objects with "==" , it only compares the pointer, not the content of the objects.
        //   if (_.isEqual(sku.sku_fields, this.selected_sku)) {
        //     return sku;
        //   }
        // }
        // return null;
      },
      sku_about: function () {
        let print = [...this.sku_tree.preOrderTraversal()];
        if (print.length > 2) {
          return 'カートに追加時にお選びいただけます。';
        } else {
          return this.minimum_sku.value.choice_name;
        }
      },
      preservation_category: function () {
        let val = this.product.preservation_category;
        if (val == 0 || val == 3) {
          return '常温';
        } else if (val == 1) {
          return '冷蔵';
        } else if (val == 2) {
          return '冷凍';
        } else {
          return '';
        }
      },
    },
    methods: {
      return_length(str) {
        return str.length;
      },
      cart_button_clicker() {
        if (this.product.sku_objects.length > 1) {
          this.sheet = !this.sheet;
        } else {
          this.add_to_cart();
        }
      },
      goToCart() {
        this.$router.push('/cart');
      },
      sku_clicked: function (node) {
        // if("sku_product" in node.value){
        //   /* update sku_key */
        //   console.log(node.key)
        //   this.selected_sku_key = node.key
        // }
        // let back_part=[...this.sku_tree.getOneChain(this.sku_tree.find(node.key))].reverse()
        // let head_part=this.sku_model.slice(0,node.depth)
        // let model =head_part.concat(back_part)
        // this.sku_model = model
        // this.selected_sku_key= model[model.length-1]

        let that = this;

        if ('sku_product' in node.value) {
          /* update sku_key */
          that.selected_sku_key = node.key;
        }
        /* update sku_model */
        let back_part = [...that.sku_tree.getOneChain(that.sku_tree.find(node.key))].reverse();
        let head_part = that.sku_model.slice(0, node.depth);
        let model = head_part.concat(back_part);
        this.sku_model = model;
        this.selected_sku_key = model[model.length - 1];
        this.selected_sku = this.sku_tree.find(this.selected_sku_key);

        // let print = tree.preOrderTraversal()
      },

      add_to_cart: function () {
        this.dialog = true;

        // console.log(this.sku_model);
        let sku_fields = [];
        for (let node_key of this.sku_model.slice(1)) {
          // type error : circular object => recursive function in class
          sku_fields.push(this.sku_tree.find(node_key).value);
        }

        // console.log(sku_fields);
        const sku_price_temp = this.selected_sku_info.value.sku_product.price || 0;

        let add_cart = {
          product_id: this.product._id,
          sku_id: this.selected_sku_key,
          is_onsale: this.product.is_onsale,
          is_alcohol: this.product.is_alcohol,
          sku_price: this.sku_tree.find(this.selected_sku_key).value.sku_product.price, //単価
          sku_sale_price: Math.floor(sku_price_temp * this.product.sale_discount), //単価
          sku_number: this.sku_number, //個数
          sku_fields: sku_fields,
          lead_time: this.product.lead_time,
          product_fields: {
            title: this.product.title,
            subtitle: this.product.subtitle,
            description: this.product.description,
            standard: this.product.standard,
            table: this.product.table,
            thumbnail: this.product.thumbnail,
            cover_images: this.product.cover_images,
            body_images: this.product.body_images,
            price: this.product.price,
            tax: this.product.tax,
            sku_objects: this.product.sku_objects,
          },

          //productの性質(fields)を記録する
        };

        // this.$store.commit('cart/setMyCart', []);
        this.$store.commit('cart/addToCart', add_cart);

        // this.$store.commit('add_to_cart', {
        //   product_id: this.product._id,
        //   product_title: this.product.title,
        //   price: this.product.price,
        // });
        this.sheet = false;

        // if(this.$store.state.logged_in){
        //   this.$router.push('/pay');
        // }else{
        //   this.$router.push('/login');
        // }
        // } else {
        //   this.inventory_error = true;
        // }
      },
      back_to_home: function () {
        this.$router.push('/');
      },
      getFields: function (data) {
        if (data.value === 'root') {
          return [];
        }
        return [...this.getFields(data.parent), data.value];
      },
      // sku number
      handlePlus() {
        this.sku_number += 1;
      },
      handleMinus() {
        this.sku_number -= 1;
      },
      staff_chat: function () {
        window.location.href = contactURL;
      },
      async send_review_clicker() {
        await Methods.sendReview({
          user_id: this.$store.state.user.user_id,
          product_id: this.product._id,
          text: this.reviewContent,
        });
        const reviewData = await Methods.getReviews({
          product_id: this.product_id,
          page: 0,
          perPage: 1000,
        });
        this.reviews = reviewData.data.reviews;
        this.reviewContent = '';
      },

      onReviewKeyDown: function () {
        this.send_review_clicker();
      },
      // async getReviews() {
      //   try {
      //     console.log(this.product_id);

      //     let { data } = await Methods.getReviews({
      //       prodcut_id: this.product_id,
      //       page: 0,
      //       perPage: 1000,
      //     });
      //     console.log(data);
      //   } catch (error) {
      //     console.log(error);
      //   }
      // },
    },
  };
</script>

<style scoped>
  * {
    margin: 0;
  }

  .add {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }

  #Footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    /*background-color: red;*/
  }

  .message {
    display: flex;
    justify-content: center;
    position: fixed;
    /* bottom: 15%; */
    left: 0;
    width: 100vw;
  }

  .message .content {
    padding: 4px;
    background-color: #272727;
    border-radius: 4px;
    font-size: 14px;
    color: white;
  }
  .u-pre-wrap {
    white-space: pre-wrap;
  }
  s .mincho {
    font-family: 'ヒラギノ明朝 ProN W6', 'HiraMinProN-W6', 'HG明朝E', 'ＭＳ Ｐ明朝', 'MS PMincho',
      'MS 明朝', serif;
  }
</style>
